<template>
	<div class="courses-page-block">
		<h1
			id="seo-title"
			class="seo-title"
		>
			Бесплатные онлайн видео-курсы для школьников и студентов
		</h1>
		<div class="content">
			<Breadcrumb :page-name="'Обучение'" />
			<div class="courses">
				<div class="sidebar">
					<h2 class="title-section">
						Онлайн курсы
					</h2>
					<p class="sidebar__text">
						Выберите нужный курс с помощью поиска или по направлениям, чтобы получить все знания наших экспертов. Выбранные курсы будут доступны вам в любое время из личного кабинета.
					</p>
					<CoursesSideBar
						:filters="filters"
						:direction="filterSections"
						:type="filterType"
						@changeParametersFilters="acceptFilter"
						@clearFilter="clearFilter"
					/>
				</div>
				<div class="courses__body">
					<div
						v-if="courses || isLoaded"
						class="search-block"
					>
						<div class="search-courses">
							<button class="search-courses__button" />
							<input
								v-model="search"
								type="text"
								class="search-courses__value"
								placeholder="Поиск по курсам и программам"
								@input="searching"
							>
							<div class="search-courses__close" />
						</div>
						<div class="title-mono">
							{{ foundCount }}: <span>{{ filters.amount }}</span>
						</div>
					</div>
					<div
						v-if="!courses || !isLoaded"
						class="preloader"
						style="margin-top: 100px"
					/>
					<div
						v-if="isLoaded && courses && courses.length"
						class="courses__list"
					>
						<CourseCard
							v-for="(item, index) in courses"
							:key="index"
							:course="item"
						/>
					</div>
					<PagePagination
						v-if="pagination.ttl > 1"
						:pagination="pagination"
						:pages="pages"
						:is-show-total="isShowTotal"
						:is-show-next="isShowNext"
						is-courses
						@changePage="selectPage"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import {debounce} from '@/assets/scripts/common'
import Breadcrumb from '@/components/Breadcrumb';
import CoursesSideBar from '@/components/learning/CoursesSideBar';
import CourseCard from '@/components/learning/CourseCard';
import PagePagination from '@/components/PagePagination';

export default {
    components: {
        Breadcrumb,
        CoursesSideBar,
        CourseCard,
        PagePagination
    },
    data: () => ({
        isLoaded: true,
        search: '',
        filters: {},
        pagination: {
            init: 0,
            crnt: 1,
            ttl: undefined
        }
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        }),
        ...mapGetters('tb', {
            courses: 'getAllCourses',
            sections: 'getAllSections',
            typesCourses: 'getAllTypesCourses'
        }),
        pages() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.filters.amount / 30) < 3 ? Math.ceil(this.filters.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.pagination.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.filters.amount) return Math.ceil(this.filters.amount / 30) > 3
            return false
        },
        isShowNext() {
            return (this.pagination.init !== this.pagination.ttl - 4) && this.isShowTotal
        },
        filterSections() {
            return this.sections && this.sections.filter(section => !!section.information_course.count).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0) || undefined
        },
        filterType() {
            return  [{ name: 'Программа', value: 'program' }, {name: 'Курс', value: 'course'}]
        },
        foundCount() {
            if (this.filters.type.value[0] === 'course') return 'Найдено курсов'
            if (this.filters.type.value[0] === 'program') return 'Найдено программ'
            return 'Найдено курсов и программ'
        }
    },
    async created() {
        await this.initFilters()
        await this.getAllSections()
        await this.getAllTypeCourses()
        await this.detectedSection()
        this.getFilterCourses(true)
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    if (callTime >= inputTime + 1000) this.getFilterCourses()
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('tb', [
            'getAllCourses',
            'getAllSections',
            'getAllTopics',
            'getAllTypeCourses',
        ]),
        detectedSection() {
            const slug = this.$route.path.match(/^\/courses\/(.+)$/) || []
            if (slug && this.sections.find(x => x.slug === slug)) {
                this.filters.sections.value = [this.sections.find(x => x.slug === slug).id]
            }
            return Promise.resolve()
        },
        initFilters(sectionsList = []) {
            this.search = ''
            this.filters = {
                amount: undefined,
                sections: {
                    value: sectionsList,
                    isShow: true,
                },
                type: {
                    value: [],
                    isShow: false,
                }
            }
        },
        getFilterCourses(init = false) {
            this.sections.forEach( item => {
                if (item.slug === this.$route.fullPath.split('/').splice(-1)[0]) {
                    let idSlug = item.id
                    this.filters.sections.value[0] !== idSlug ? this.$router.push('/courses') : ''
                }
            })
            this.isLoaded = false
            return this.getAllCourses({
                page: this.pagination.crnt,
                name: this.search,
                section: this.filters.sections.value.join(),
                type: this.filters.type.value.join()
            })
                .then(response => {
                    this.filters.amount = response.count
                    this.pagination.ttl = Math.ceil(response.count / 30)
                })
                .finally(() => this.isLoaded = true )
        },
        selectPage(obj) {
            this.pagination.crnt = obj.item
            this.getFilterCourses()
            if (this.pagination.ttl === obj.item) {
                if (this.pagination.ttl >= 4) this.pagination.init = this.pagination.ttl - 4
                else this.pagination.init = 0
            } else if (obj.index === 0 && obj.item > 1) this.pagination.init--
            else if (obj.index === 30 && obj.item < this.pagination.ttl - 1) this.pagination.init++
            this.$scrollTo(document.querySelector('header.header-role'))
        },
        searching() {
            this.pagination = {
                init: 0,
                crnt: 1,
                ttl: undefined
            }
            this.callDebounceWrapper()
        },
        acceptFilter(filters) {
            this.filters.sections.value = filters.localDirection
            this.filters.type.value = filters.localType
            this.pagination.crnt = 1
            this.getFilterCourses()
        },
        clearFilter() {
            if(this.$route.query.type) {
                this.$router.push('/courses')
            }
            this.search = ''
            this.filters = {
                amount: undefined,
                sections: {
                    value: [],
                    isShow:false,
                },
                type: {
                    value: [],
                    isShow:false,
                }
            }
            this.getFilterCourses()
        }
    }
}
</script>

<style lang="less">
@import '../assets/styles/pages/courses';
</style>
