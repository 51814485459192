<template>
	<div
		class="filters"
		:class="{'filters--active': isShowFilters}"
	>
		<div class="filters__header">
			<h4 class="title-element">
				Фильтры
			</h4>
			<div
				class="filters__toggle title-mono"
				@click="isShowFilters = !isShowFilters"
			>
				{{ isShowFilters? 'Скрыть фильтры' : 'Показать фильтры' }}
			</div>
		</div>
		<div
			class="filters__content"
			:style="isShowFilters ? 'display: block' : 'display: none'"
		>
			<FilterSideBar
				v-if="direction"
				name="sections"
				title-filter="направление"
				:filters="direction"
				:is-clear-filter="isClearFilterDirection"
				@setFilters="setDirection"
				@setClearedFilter="setFilterDirection"
			/>
			<FilterSideBar
				v-if="type"
				name="type"
				title-filter="тип обучения"
				:filters="type"
				:is-clear-filter="isClearFilterType"
				@setFilters="setType"
				@setClearedFilter="setFilterType"
			/>
			<div class="filters__controls">
				<button
					class="button"
					@click="acceptFilter"
				>
					Применить
				</button>
				<button
					class="button button--ghost-orange"
					@click="clearFilter"
				>
					Очистить фильтры
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import FilterSideBar from '@/components/learning/FilterSideBar';

export default {
    name: 'CoursesSideBar',
    components: {
        FilterSideBar
    },
    props: {
        filters: {
            type: Object,
            default: () => {}
        },
        direction: {
            type: Array,
            default: null
        },
        type: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        localDirection: [],
        localType: [],
        isClearFilterDirection: false,
        isClearFilterType: false,
        isShowFilters: false
    }),
    mounted () {
        if(this.filters.type)  {
            this.localType = this.filters.type.value
        }
        if(this.filters.direction)  {
            this.localDirection = this.filters.sections.value
        }
    },
    methods: {
        isNotEmptyFilters() {
            return (
                this.localDirection.length ||
                this.localType.length
            );
        },
        setDirection(direction) {
            this.localDirection = direction;
        },
        setType(type) {
            this.localType = type;
        },
        setFilterDirection(filter) {
            this.isClearFilterDirection = filter;
        },
        setFilterType(filter) {
            this.isClearFilterType = filter;
        },
        acceptFilter() {
            this.$emit('changeParametersFilters', {
                localDirection: this.localDirection,
                localType: this.localType
            })
        },
        clearFilter() {
            this.isClearFilterDirection = true
            this.isClearFilterType = true
            this.$scrollTo(document.querySelector('header.header-role'))
            this.localDirection = []
            this.localType = []
            this.$emit('clearFilter', {
                localDirection: this.localDirection,
                localType: this.localType
            })
        }
    }
};
</script>
