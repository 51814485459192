<template>
	<div class="course">
		<router-link
			:to="course.type === 'course' ? `/courses/${course.slug}-${course.id}` : `/courses/program/${course.slug}/${course.id}`"
			class="course__pic"
			:style="courseBgrImage(course.image_course)"
		/>
		<div class="course__content">
			<div class="course__info">
				<div class="course__members">
					{{ course.students_enrolled }}
				</div>
				<div class="course__duration">
					{{ course.duration | durationFilter }}
				</div>
			</div>
			<router-link
				:to="course.type === 'course' ? `/courses/${course.slug}-${course.id}` : `/courses/program/${course.slug}/${course.id}`"
				class="course__title"
			>
				{{ course.name }}
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
    name: 'CourseCard',
    props: {
        course: {
            type: Object,
            default: null
        }
    },
    methods: {
        courseBgrImage(image) {
            let course_cover = ''
            if (image.length > 1 && Array.isArray(image)) {
                image.forEach(item => {
                    if (this.$route.meta.isCourses) {
                        if (item.type === 'course_cover') course_cover = item.image
                    }
                })
                return `background-image: url(${course_cover});`
            }
            else {
                if (image) return `background-image: url(${image[0].image});`
                else return 'background-image: url(/staticfiles/img/landscape-image.png);'
            }
        }
    }
};
</script>

<style scoped>

</style>
