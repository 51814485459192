<template>
	<div class="filters__field">
		<h6 class="title-text">
			Выберите {{ titleFilter }}
		</h6>
		<c-select
			:options="shownFiltersList"
			:name="titleFilter === 'направление' ? 'Все направления' : 'Тип обучения'"
			:is-clear="isClearFilter"
			is-courses
			@cleared="() => ( this.$emit('setClearedFilter', false))"
			@selected="changeValue"
		/>
	</div>
</template>

<script>
export default {
    name: 'FilterSideBar',
    props: {
        name: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => []
        },
        titleFilter: {
            type: String,
            default: 'Заголовок фильтра'
        },
        isClearFilter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        shownFiltersList() {
            return this.filters.map(x => x.name);
        }
    },
    methods: {
        changeValue(e) {
            let localFilters = []
            let filter = this.filters.filter(x => x.name === e)
            this.titleFilter === 'направление' ? localFilters.push(filter[0].id) : localFilters.push(filter[0].value)
            this.$emit('setFilters', localFilters);
        }
    }
};
</script>
